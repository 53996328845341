import React from 'react'
import './useraccess.css'
import Auth from '../../components/loginSignup/Auth';
import Header from '../../components/topbar/Topbar'

export default function Useraccess() {
    return (
        <div >
          
            <Auth />

        </div>
    )
}
