import React from 'react';
import "./team.css";


export default function Team() {
  return (
    <div className='mainwrapper'>
    <h2>Meet the team</h2> <hr></hr>
      <div className="flexbox">

        <div class="grid-item">
          <div className="gridImageContainer">
            <img src="/assets/teams/ceopic.png" className='teamprofilePic' alt="" />
            <h4>Prabhat Jha</h4>
            <h6>Founder & CEO</h6>
          </div>
          <div className="gridSocialbox">
            <img src="/assets/Social Icons/facebook.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/twitter.png" alt="" className='teamsocialIcon' />
            <a href="https://www.linkedin.com/in/prabhat-jha-b42017185/"><img src="/assets/Social Icons/linkedin.png" alt="" className='teamsocialIcon' /></a>
            <img src="/assets/Social Icons/email.png" alt="" className='teamsocialIcon' />
          </div>
        </div>

        <div class="grid-item">
          <div className="gridImageContainer">
            <img src="/assets/teams/IMG_1097.jpg" className='teamprofilePic' alt="" />
            <h4>Ayush Mishra</h4>
            <h6>Co-Founder & MD</h6>
          </div>
          <div className="gridSocialbox">
            <img src="/assets/Social Icons/facebook.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/twitter.png" alt="" className='teamsocialIcon' />
            <a href="/"> <img src="/assets/Social Icons/linkedin.png" alt="" className='teamsocialIcon' /></a>
            <img src="/assets/Social Icons/email.png" alt="" className='teamsocialIcon' />
          </div>
        </div>

        <div class="grid-item">
          <div className="gridImageContainer">
            <img src="/assets/teams/IMG_1103.jpg" className='teamprofilePic' alt="" />
            <h4>Krishna Darshan</h4>
            <h6>Co-Founder & Head-Operation</h6>
          </div>
          <div className="gridSocialbox">
            <img src="/assets/Social Icons/facebook.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/twitter.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/linkedin.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/email.png" alt="" className='teamsocialIcon' />
          </div>
        </div>

        <div class="grid-item">
          <div className="gridImageContainer">
            <img src="/assets/teams/IMG_1328.jpg" className='teamprofilePic' alt="" /><br />
            <h4>Vinit Kunal</h4>
            <h6>VP</h6>
          </div>
          <div className="gridSocialbox">
            <img src="/assets/Social Icons/facebook.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/twitter.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/linkedin.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/email.png" alt="" className='teamsocialIcon' />
          </div>
        </div>

        <div class="grid-item">
          <div className="gridImageContainer">
            <img src="/assets/teams/IMG_1174.jpg" className='teamprofilePic' alt="" /><br />
            <h4>Srijan Dixit</h4>
            <h6>Head - Marketing & Sales</h6>
          </div>
          <div className="gridSocialbox">
            <img src="/assets/Social Icons/facebook.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/twitter.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/linkedin.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/email.png" alt="" className='teamsocialIcon' />
          </div>
        </div>

        <div class="grid-item">
          <div className="gridImageContainer">
            <img src="/assets/teams/IMG_1110.jpg" className='teamprofilePic' alt="" /><br />
            <h4>Harshit Srivastava</h4>
            <h6>Head - Technology</h6>
          </div>
          <div className="gridSocialbox">
            <img src="/assets/Social Icons/facebook.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/twitter.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/linkedin.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/email.png" alt="" className='teamsocialIcon' />
          </div>
        </div>

        <div class="grid-item">
          <div className="gridImageContainer">
            <img src="/assets/teams/IMG_5666.jpg" className='teamprofilePic' alt="" /><br />
            <h4>Aditya Anand</h4>
            <h6>Head - Maintenance</h6>
          </div>
          <div className="gridSocialbox">
            <img src="/assets/Social Icons/facebook.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/twitter.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/linkedin.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/email.png" alt="" className='teamsocialIcon' />
          </div>
        </div>
        
        <div class="grid-item">
          <div className="gridImageContainer">
            <img src="/assets/teams/IMG_0001.jpg" className='teamprofilePic' alt="" /><br />
            <h4>Amarjeet Kumar</h4>
            <h6>Head - HR & Finance</h6>
          </div>
          <div className="gridSocialbox">
            <img src="/assets/Social Icons/facebook.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/twitter.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/linkedin.png" alt="" className='teamsocialIcon' />
            <img src="/assets/Social Icons/email.png" alt="" className='teamsocialIcon' />
          </div>
        </div>

      </div>

    </div>
  )
}
