import React from 'react'
import './yoga.css'
import YogaMenu from '../../components/yoga_components/yogamenu/YogaMenu'
import Header from "../../components/topbar/Topbar"
import Footer from "../../components/footer/Footer"

export default function Yoga() {
    return (
        <div className='yogaWrapper'>

            <Header />
            <YogaMenu />
            <Footer />

        </div>
    )
}
