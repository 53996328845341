import React from 'react'
import './bmi.css'
// import Graph from '../graph/Graph'


export default function BMI() {
  return (
    <div className='bmicalculatormainwrapper'>

      <div className="bmimainwrapperheading">
        BMI Calculators
      </div>
      <div className="bmicalculatorwrapper">

        <div className="bmicalculatorcontainer">
          <div className="bmicalculatorwrapperleft">

            <div className="bmicalculatorinputsbox">
              <label id='bmicalculatorinputslabel' htmlFor="age">age</label>
              <input id='bmicalculatorinputs' type="range" min={4} max={125} step={1} defaultValue={25} />
            </div>
            <div className="bmicalculatorinputsbox">
              <label id='bmicalculatorinputslabel' htmlFor="age">height</label>
              <input id='bmicalculatorinputs' type="range" min={4} max={8} step={0.1} defaultValue={6} />
            </div>
            <div className="bmicalculatorinputsbox">
              <label id='bmicalculatorinputslabel' htmlFor="age">weight</label>
              <input id='bmicalculatorinputs' type="range" min={30} max={150} step={1} defaultValue={65} />
            </div>
            <div className="bmicalculatortotal">
            BMI =
            </div>

          </div>

          <div className="bmicalculatorwrapperright">

            ddbryfgxnyjn
            {/* <Graph/> */}
          </div>
        </div>
        <div class="bmicalculatorpromotions">
          <div class="sc-hdzxpq-16 hhQhSY">earn up to ₹1000 cashback by paying your credit card bill on CRED</div>
          <div class="sc-hdzxpq-17 iNPcPP">Join CRED</div>
        </div>

      </div>

    </div>
  )
}
