import React from 'react'
import './contact.css'
import { Email, Home, Phone, Print } from '@mui/icons-material';


export default function ContactUs() {
    return (
        <div>
            <div className='contactmainWrapper'>
                <div className='contactmainWrapperHeading'>
                    <h1>Contact us</h1>
                </div>

                <div className="contactmainWrapperForm">

                    <div className="contactmainWrapperFormLeft">
                        <div className="contactformAdressMapBox">
                            <iframe className='contactformAdressMap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d759.6555858414046!2d-0.15939053511091444!3d51.52374558499411!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761acf33628211%3A0x445d7677a88322e1!2s221B%20Baker%20St%2C%20London%20NW1%206XE%2C%20UK!5e0!3m2!1sen!2sin!4v1690041033237!5m2!1sen!2sin" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>

                    <div className="contactmainWrapperFormRight">
                        <h1 id='contactformHeading'>After a long time, let's talk</h1>

                        <div className='contactformWrapper'>
                            <form action="">
                                <label id="contactForm_label">Full Name</label><br />
                                <input type="text" id="contactForm_fname" name="fname" value="Sherlock Holmes" placeholder='Enter full name' /><br />

                                <label id="contactForm_label">Email ID</label><br />
                                <input type="email" id="contactForm_email" name="email" value="holmes.sherlock@gmail.com" placeholder='Enter email address' /><br />

                                <label id="contactForm_label">Number</label><br />
                                <input type="tel" id="contactForm_number" name="number" value="1211000000"
                                    placeholder='Enter mobile number' /><br />

                                <label id="contactForm_label">Message</label><br />
                                <textarea name="message" id="contactForm_message" cols="30" rows="10">This is the sample data</textarea><br />

                                <input id='contactForm_Submit' type="submit" value="Submit" />
                                <input id='contactForm_Reset' type="reset" value="Reset" />

                            </form>

                            {/* <img id='contactformIcon' src="/assets/onlinecontent/contact.png" alt="" /> */}

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
