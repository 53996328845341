import React from 'react'

import './exercisemaincard.css'
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';


export default function ExerciseMainCard() {
    return (
        <div className='exercisemainwrapper'>

            <div className="mainpageheader">
                <h2 className="mainpageheaderH2">Exercises</h2>
            </div>

            <CardGroup >
                <Card >
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/Biceps.jpg" />
                    <Card.Body>
                        <Card.Title>Biceps</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/Trap.jpg" />
                    <Card.Body>
                        <Card.Title>Traps</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/lowerBack.jpg" />
                    <Card.Body>
                        <Card.Title>Lower back</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/Calves.jpg" />
                    <Card.Body>
                        <Card.Title>Calves</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>


            <CardGroup>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/Soleus.png" />
                    <Card.Body>
                        <Card.Title>Soleus</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/forearms.jpg" />
                    <Card.Body>
                        <Card.Title>Forearms</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/hamstring.png" />
                    <Card.Body>
                        <Card.Title>Hamstrings</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                {/* <Card>
                    <Card.Img variant="top" src="/public/assets/exercise_Thumbnails/hamstring.jpeg" />
                    <Card.Body>
                        <Card.Title>Hamstrings</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card> */}
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/Lats.png" />
                    <Card.Body>
                        <Card.Title>Lats</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>


            <CardGroup>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/shoulders.png" />
                    <Card.Body>
                        <Card.Title>Shoulders</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/Lateral Deltoid.png" />
                    <Card.Body>
                        <Card.Title>Lateral Deltoid</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/Anterior Deltoid.jpg" />
                    <Card.Body>
                        <Card.Title>Anterior Deltoid</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/Posterior Deltoid.jpg" />
                    <Card.Body>
                        <Card.Title>Posterior Deltoid</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>


            <CardGroup>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/Triceps.jpg" />
                    <Card.Body>
                        <Card.Title>Triceps</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/Quads.jpg" />
                    <Card.Body>
                        <Card.Title>Quads</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/chest.jpg" />
                    <Card.Body>
                        <Card.Title>Chest</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src="/assets/exercise_Thumbnails/Oblique.jpg" />
                    <Card.Body>
                        <Card.Title>Obliques</Card.Title>
                        <Card.Text>

                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>

        </div>
    )
}


