import React from 'react'

import Topbar from "../../components/topbar/Topbar"
import Footer from "../../components/footer/Footer"
import HomeFeedsSection from "../../components/home_feeds/HomeFeedsSection"
import { Link } from "react-router-dom";


export default function Home() {


  return (

    <div className="homeContainer">
      <Topbar />
      <HomeFeedsSection />
      <Footer />

    </div>

  )
}