import React from 'react'

import "./footer.css"
import { Email, Home, Phone, Print } from '@mui/icons-material';

export default function Footer() {
    return (

        <div className="footerContainer">

            <div className="footerContainerTop">
                <div className="footerContainerLeft">
                    <span className="footerLeftHeading">Quick links</span>
                    <ul className="footerLeftMenu">
                        <a href="/home"> <li className="footerLeftMenuItems" >Home</li></a>
                        <a href="/about"> <li className="footerLeftMenuItems">About Us</li></a>
                        <a href="/contact"> <li className="footerLeftMenuItems">Contact Us</li></a>
                        <li className="footerLeftMenuItems">Privacy Policy</li>
                        <li className="footerLeftMenuItems">Blogs</li>
                        <li className="footerLeftMenuItems">Career</li>
                    </ul>
                </div>

                <div className="footerContainerCenter">
                    <span className="footerCenterHeading">Latest Blog</span>
                    <ul className="footerCenterMenu">
                        <li className="footerCenterMenuItems">Advanced Kettlebell Workout</li>
                        <li className="footerCenterMenuItems">Beginner Barbell Workout</li>
                        <li className="footerCenterMenuItems">Intermediate Barbell Workout</li>
                        <li className="footerCenterMenuItems">Beginner Bodyweight Workout</li>
                        <li className="footerCenterMenuItems">Chat GPT Bodyweight for Beginnners</li>
                        <li className="footerCenterMenuItems">Career</li>
                    </ul>
                </div>

                <div className="footerContainerRight">
                    <span className="footerRightHeading">Address</span>
                    <ul className="footerRightMenu">
                        <li className="footerRightMenuItems" > <Home />  New York, NY 10012, US</li>
                        <li className="footerRightMenuItems"> <Email />  query@drawwfit.com</li>
                        <li className="footerRightMenuItems"><Phone /> + 01 234 567 88</li>
                        <li className="footerRightMenuItems"><Print /> + 01 234 567 89</li>
                    </ul>
                </div>

            </div>

            <div className="homesubscribesection">
                <input type="text" name="subscribe" id="home_subscribe_input" />
                <button id="homesubscribesubmit"> Subscribe </button>
            </div>

            <div className="footerBottomContainer">
                <ul className="footerBoxSocial">
                    <li className="footerSocialItems" ><img id="footerSocialItems" src="/assets/Social Icons/facebook.png" alt="" /></li>
                    <li className="footerSocialItems"><img id="footerSocialItems" src="/assets/Social Icons/twitter.png" alt="" /></li>
                    <li className="footerSocialItems"><img id="footerSocialItems" src="/assets/Social Icons/instagram.png" alt="" /></li>
                    <li className="footerSocialItems"><img id="footerSocialItems" src="/assets/Social Icons/linkedin.png" alt="" /></li>
                    <li className="footerSocialItems"><img id="footerSocialItems" src="/assets/Social Icons/telegram.png" alt="" /></li>
                    <li className="footerSocialItems"><img id="footerSocialItems" src="/assets/Social Icons/threads.png" alt="" /></li>
                    <li className="footerSocialItems"><img id="footerSocialItems" src="/assets/Social Icons/tumblr.png" alt="" /></li>
                    <li className="footerSocialItems"><img id="footerSocialItems" src="/assets/Social Icons/email.png" alt="" /></li>
                </ul>

                <div className="homecopyright">
                    <p className="sitecopyright">© 2023 Copyright: drawwfit.com,  designed by Prabhat Jha </p>
                </div>

            </div>

        </div>
    )
}