import React from 'react'
import ContactUs from '../../components/contactus/Contact'
import Topbar from '../../components/topbar/Topbar'
import Footer from '../../components/footer/Footer'

export default function Contact() {
  return (
    <div>

      <Topbar />
      <ContactUs />
      <Footer />
      
    </div>
  )
}
