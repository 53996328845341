import React from 'react'
import './yogamenu.css'
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';




export default function YogaMenu() {


    var data = [
        {
            "heading": "Standing",
            "content": "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
            // "link": "/assets/yoga/standing.png",
            "link":"https://www.imghippo.com/images/1694630606.png"
        },
        {
            "heading": "Seated",
            "content": "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
            // "link": "/assets/yoga/seated.png",
            "link":"https://www.imghippo.com/images/1694630887.png"
        },
        {
            "heading": "Supine",
            "content": "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
            // "link": "/assets/yoga/supine.png"
            "link":"https://www.imghippo.com/images/1694630960.png"
        },
        {
            "heading": "Prone",
            "content": "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
            // "link": "/assets/yoga/prone.jpg",
            "link":"https://www.imghippo.com/images/1694631000.jpg"
        },
        {
            "heading": "Arm & Leg Support",
            "content": "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
            // "link": "/assets/yoga/Arm Leg.png",
            "link":"https://www.imghippo.com/images/1694631056.png"
        },
        {
            "heading": "Arm Balance & Inversion",
            "content": "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
            // "link": "/assets/yoga/Arm Inversion.png",
            "link": "https://www.imghippo.com/images/1694631080.png"
        }
    ];

    console.log(data);

    let itemList = [];

    data.forEach((item, index) => {
        itemList.push(<li key={index}>{item}</li>)
        //   console.log(itemList[index].props.children);
    })

    return (

        <div className='yogamenuWrapper'>

            <div className="mainpageheader">
                <h2 className="yogamenuheader">Yoga</h2>
            </div>


            <CardGroup>
                <Card>
                    <Card.Img variant="top" src={data[0].link} />
                    <Card.Body>
                        <Card.Title>{data[0].heading} </Card.Title>

                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={data[1].link} />
                    <Card.Body>
                        <Card.Title>{data[1].heading} </Card.Title>

                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={data[2].link} />
                    <Card.Body>
                        <Card.Title>Supine</Card.Title>

                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={data[3].link} />
                    <Card.Body>
                        <Card.Title>Prone</Card.Title>

                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={data[4].link} />
                    <Card.Body>
                        <Card.Title>Arm & Leg Support</Card.Title>

                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={data[5].link} />
                    <Card.Body>
                        <Card.Title>Arm Balance & Inversion</Card.Title>

                    </Card.Body>
                </Card>

            </CardGroup>

        </div>
    );
}
