import React from 'react'
import Home from "./pages/home/Home";
import About from "./pages/about/About"
import Contact from "./pages/contact/Contact";
import Exercise from "./pages/exercise/Exercise"
import Login from "./pages/useraccess/Useraccess"
import Yoga from "./pages/yoga/Yoga"
import Calculator from './pages/calculators/Calculators'
import Sample from './components/exercise_component/exercisesingle_Card/ExerciseSingleCard'

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route index element={

        <div>
          <Home />
        </div>

      } />

      <Route path={'/'} element={<Home />} />
      <Route path={'/home'} element={<Home />} />
      <Route path={'/about'} element={<About />} />
      <Route path={'/contact'} element={<Contact />} />
      <Route path={'/exercise'} element={<Exercise />} />
      <Route path={'/login'} element={<Login />} />
      <Route path={'/calculators'} element={<Calculator />} />
      <Route path={'/yoga'} element={<Yoga />} />
      <Route path={'/sample'} element={<Sample />} />
      <Route path="*" element={<div className="stest"> 404, Page not found</div>} />


    </Routes>



  );
}
export default App;
