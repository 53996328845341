import React from 'react'
import "./basicinfo.css";



export default function BasicInfo() {
    return (
        <div className='mainwrapper1'>
            <div className='aboutHeading'>
                <h2>About Us</h2><hr></hr>
            </div>
            <div className='mainwrapperBox'>
                <div className='mainwrapperBoxHeading'>
                    <h3 id='basicinfoH3'>Mission</h3>
                </div>
                <div className='mainwrapperBoxContent'>
                    We are a next-generation software platform dedicated to making it easy for fitness professionals to manage their entire fitness business in one place. <br /><br />
                    From booking and scheduling appointments, to creating and logging workouts, sending fitness assessments and capturing leads, and delivering fitness in-person and online, our goal is to make YOUR fitness brand a success. <br /> <br />
                    We offer fully custom-branded fitness apps on the web, iOS, and Android so that your business can grow and develop deep client relationships, in-person and online, without diluting your brand by sending your clients to download other company’s apps in the App Store or forcing them to create multiple accounts on various 3rd-party fitness products (that you have to administer on the back end with a tangled web of Zapier integrations, legacy spreadsheets, and no small amount of staff and client confusion).<br /><br />
                    Simpler is better.<br /> <br />
                    We offer ONE software platform to manage your entire fitness business.<br /> <br />
                    On YOUR brand.<br /> <br />
                </div>

                <div className='mainwrapperBoxHeading'>
                    <h3 id='basicinfoH3'>Our Values</h3>
                </div>
                <div className='mainwrapperBoxContent'>
                    We make fitness businesses happy and successful. <br /><br />
                    That’s it.<br /><br />
                    Our focus is on YOU.<br /><br />
                    We love fitness businesses, and they love us.<br /><br />
                    (But don’t take our word for it. Read our reviews here!)<br /><br />
                    We solve problems and provide outcomes; we don’t just ship new products.<br /><br />
                    We are a high-growth B2B SaaS fintech company but we don’t ever forget that our #1 goal is to make fitness professionals happy and successful. We view our next generation payments platform as integral to our success and our customer’s success. When our customers generate more revenue, we all win.<br /><br />
                    We are only happy and successful if our customers are happy and successful.<br /><br />
                    Ready to join us?<br /><br />
                </div>
            </div>

        </div>
    )
}
