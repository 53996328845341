import React from 'react'
import BasicInfo from "../../components/aboutus/basicInfo/BasicInfo"
import Timeline from "../../components/aboutus/timeline/Timeline"
import Team from "../../components/aboutus/team/Team"
import Header from "../../components/topbar/Topbar"
import Footer from "../../components/footer/Footer"


export default function About() {
  return (
    <div className='mainwrappperAbout'>
      <Header />
      <BasicInfo />
      <Timeline />
      <Team />
      <Footer />

    </div>
  )
}
