import React from 'react'

import "./topbar.css"
import { Search } from '@mui/icons-material';

export default function Topbar() {

    return (
        <div className="topbarContainer">
            <div className="topbarfirstwrapper">
                <div className="topbarLeft">
                    <a href="/home"><img src="/assets/homeasst/logos.gif" alt="" className="mainlogo" /></a>
                </div>

                <div className="topbarCenter">
                    <div className="searchbar">
                        <input placeholder="Search anything" className="searchInput" />
                        <Search className="searchIcon" />
                    </div>
                </div>

                <div className="topbarRight">


                    <div className="topbarIcons">
                        <span className="topbarLink">My Assistant</span>
                        <div class="dropdown">
                            <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Lang
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <a class="dropdown-item" href="#">Eng</a>
                                <a class="dropdown-item" href="#">Hin</a>
                                <a class="dropdown-item" href="#">Maithili</a>
                            </div>
                        </div>

                        <div className="hometopbarlogin">
                            <span className="topbarlinklogin">Login</span>
                            <span className="topbarlinkregister">Register</span>
                        </div>
                        <img src="/assets/homeasst/profilepic.png" alt="" className="profileimg" />

                    </div>

                </div>
            </div>


            <div className="topbarsecondwrapper">
                <div className="topbarLinks">
                    <ul className="headerMenuList">
                        <li className="headerMenuListItems"> <a href="/exercise"><span className="topbarLink">Exercise </span></a></li>
                        <li className="headerMenuListItems"> <a href="/yoga"><span className="topbarLink">Yoga </span></a></li>
                        <li className="headerMenuListItems"> <a href="/"><span className="topbarLink">Diet Plan </span></a></li>
                        <li className="headerMenuListItems"> <a href="/"><span className="topbarLink">Sports </span></a></li>
                        <li className="headerMenuListItems"> <a href="/"><span className="topbarLink">Store </span></a></li>
                        <li className="headerMenuListItems"> <a href="/"><span className="topbarLink">Home Setup </span></a></li>

                        {/* <li className="headerMenuListItems"><span className="topbarLink">Yoga</span></li>
                        <li className="headerMenuListItems"><span className="topbarLink">Diet Plan</span></li>
                        <li className="headerMenuListItems"><span className="topbarLink">Sports</span></li>
                        <li className="headerMenuListItems"><span className="topbarLink">Store</span></li>
                        <li className="headerMenuListItems"><span className="topbarLink">Home Setup</span></li> */}
                    </ul>

                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Calculator
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#">BMI Calculator</a>
                            <a class="dropdown-item" href="#">Calorie Calculator</a>
                            <a class="dropdown-item" href="#">Body Fat Calculator</a>
                            <a class="dropdown-item" href="#">BMR Calculator</a>
                            <a class="dropdown-item" href="#">Ideal Weight Calculator</a>
                            <a class="dropdown-item" href="#">Pace Calculator</a>
                            <a class="dropdown-item" href="#">Macro Calculator</a>
                            <a class="dropdown-item" href="#">Protein Calculator </a>
                        </div>
                    </div>

                </div>
            </div>

        </div>


    )
}