import React from 'react'
import './exercise.css'
import ExerciseMainCard from '../../components/exercise_component/exercisemain_Card/ExerciseMainCard'
// import SampleExercise from '../../components/exercise_component/exercisesingle_Card/ExerciseSingleCard'
import Header from "../../components/topbar/Topbar"
import Footer from "../../components/footer/Footer"


export default function Exercise() {
    return (


        <div className="mainwrapper">
            <Header />


            <div className='exercisewrapperbox'>
                <ExerciseMainCard />
                {/* <SampleExercise /> */}
            </div>


            <Footer />
        </div>

    )
}
