import React from 'react'
import "./homewrapper.css";
import { Search } from '@mui/icons-material';


export default function Homewrapper(props) {
    //   console.log(props.resp);
    var heading = props.resp.heading;
    var content = props.resp.content;
    var link = props.resp.link;

    return (
        <div>

            <div className="mainWrapper">

                <div className="mainWrapperBox">
                    <div className="mainWrapperBoxLeftContent">
                        <h1 id="mainWrapperBoxLeftContentHeading">{heading}</h1>
                        <p id="mainWrapperBoxLeftContentparagraph">{content}</p>
                    </div>
                    <div className="mainWrapperBoxRightContent">
                        <img src={link} className="wrapperImage" alt="" />
                        {/* <video class="tw-max-w-full" playsinline="" preload="metadata" autoplay="" loop src="/assets/videos/exercise.mp4"></video> */}
                        {/* <video width="320" height="240" autoplay loop muted playsinline="">
                            <source src="/assets/videos/exercise.mp4" type="video/mp4" />
                        </video> */}
                    </div>
                </div>

            </div>
        </div>
    )
}
