import React from 'react'
import "./timeline.css"


export default function Timeline() {
  return (
    <div className='mainwrapper'>

      <h2>Our journey so far</h2> <hr></hr><br /><br />

      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="main-timeline">
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-year">july 2023</div>
                  <h3 class="title">Conquering the Asian Territory</h3>
                  <p class="description">
                    With the backing of Greenoaks, Sequoia India, Lightspeed India, Hero enterprise and China Lodging Group as its solid group of investors, Oyo is not far from becoming a global success story, perhaps to some extent it has. Today it has more than 330,000 rooms in 500 cities globally. And Agarwal, still young and who has a long way ahead plans to make it the world's largest oldest chain by 2023!</p>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-year">Jan 2023</div>
                  <h3 class="title">Becoming the Face of Indian Start-ups</h3>
                  <p class="description">
                    In January, first month of the year itself, the hotel chain hit the 1 million check-ins mark and also made an advent outside India into the Asia Pacific market, beginning with Malaysia.</p>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-year">Oct 2022</div>
                  <h3 class="title">App launch</h3>
                  <p class="description">
                    Oyo app was launched. The mobile app was a catalyst in writing the success story of this hotel chain start-up that was to follow. It is a user-friendly app that helps customers to book rooms directly.</p>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-year">May 2022</div>
                  <h3 class="title">Our First funding</h3>
                  <p class="description">
                    In May of this year, Oyo received funding of a whopping amount of $25 million from its investors namely Lightspeed India, Seqoiua and others.</p>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-year">Jan 2022</div>
                  <h3 class="title">Our Co-Founder</h3>
                  <p class="description">
                    Agarwal was also selected for Thiel Fellowship, a 2-year-old program started by the founder of Paypal which gave him funding of $100,000 to pursue and make real the idea of his start-up. Oyo was launched in Gurgaon.</p>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-year">August 2021</div>
                  <h3 class="title">How it all Started</h3>
                  <p class="description">
                    Ritesh Agarwal, an 18-year-old college dropout, seemed to harbour dreams of entrepreneurship which compelled him to take the plunge with his first start-up Oravel. Oravel enabled listing and budget accommodation of hotel rooms and it, later on, got converted to become Oyo rooms.</p>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>


    </div>
  )
}
