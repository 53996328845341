import React from 'react'
import "./homeFeedsSection.css"
import { Search } from '@mui/icons-material';
import Homewrapper from "../home_feeds/homeWrapper/Homewrapper"

export default function HomeFeedsSection() {

    var data = [
        {
            "heading": "Exercise",
            "content": "Exercise is any bodily activity that enhances or maintains physical fitness and overall health and wellness. It is performed for various reasons, to aid growth and improve strength, prevent aging, develop muscles and the cardiovascular system",
            "link": "/assets/homeasst/exercise.png"
            // "link": "https://youtu.be/jcFyMho0Dq0"
        },
        {
            "heading": "Yoga",
            "content": "Exercise is any bodily activity that enhances or maintains physical fitness and overall health and wellness. It is performed for various reasons, to aid growth and improve strength, prevent aging, develop muscles and the cardiovascular system",
            "link": "/assets/homeasst/yoga.png"
            // "link": "https://youtu.be/AbJEmmqa6WI"

        },
        {
            "heading": "Diet Plan",
            "content": "Exercise is any bodily activity that enhances or maintains physical fitness and overall health and wellness. It is performed for various reasons, to aid growth and improve strength, prevent aging, develop muscles and the cardiovascular system",
            "link": "/assets/homeasst/diet.png"
            //   "link": "https://youtu.be/bHMvmCCj_dM"
        },
        {
            "heading": "Calculator",
            "content": "Exercise is any bodily activity that enhances or maintains physical fitness and overall health and wellness. It is performed for various reasons, to aid growth and improve strength, prevent aging, develop muscles and the cardiovascular system",
            "link": "/assets/homeasst/calculate.png"
            // "link": "https://youtu.be/NstDk2uRQ3c"
        },
        {
            "heading": "Sports",
            "content": "Exercise is any bodily activity that enhances or maintains physical fitness and overall health and wellness. It is performed for various reasons, to aid growth and improve strength, prevent aging, develop muscles and the cardiovascular system",
            "link": "/assets/homeasst/play.png"
            // "link": "https://youtu.be/Qdo0IE2zBH8"
        },
        {
            "heading": "My Assistant",
            "content": "Exercise is any bodily activity that enhances or maintains physical fitness and overall health and wellness. It is performed for various reasons, to aid growth and improve strength, prevent aging, develop muscles and the cardiovascular system",
            "link": "/assets/homeasst/assistant.png"
            // "link": "https://youtu.be/9WAACSK1HVY"
        }
    ];


    //   console.log(data[0]);
    return (
        <div>

            <div className="mainWrapper">

                <div className="mainWrapperIntro">
                    <h1 id="mainWrapperIntroHeading">The expert in anything  was once a beginner.</h1>
                    {/* <h1 id="mainWrapperIntroHeading">Everything you want is outside of your comfort zone.</h1> */}
                    <h3 id="mainWrapperIntroparagraph">join 1M+ members of Fitness Freak on DrawwFit </h3>
                    <button id="homepageExpertButton">Talk to Expert</button>
                </div>

                <Homewrapper resp={data[0]} />
                <Homewrapper resp={data[1]} />
                <Homewrapper resp={data[2]} />
                <Homewrapper resp={data[3]} />
                <Homewrapper resp={data[4]} />
                <Homewrapper resp={data[5]} />

            </div>

        </div>
    )
}
