import React from 'react'
import BMI from '../../components/calculators/bmi/BMI'

export default function Calculators() {
  return (
    <div>

      <BMI />

    </div>
  )
}
