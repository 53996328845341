import React from 'react'
import './exercisesinglecardStyle.css'
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';


let sampledata = [{
  "bodyPart": "waist",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/UV2LSnZKvlgBs1",
  "id": "0001",
  "name": "3/4 sit-up",
  "target": "abs"
},
{
  "bodyPart": "waist",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/tDYIJEyWZOgsg1",
  "id": "0002",
  "name": "45° side bend",
  "target": "abs"
},
{
  "bodyPart": "waist",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/IenUOuq8bCTiLh",
  "id": "0003",
  "name": "air bike",
  "target": "abs"
},
{
  "bodyPart": "upper legs",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/N7JeYaD3mSLBUr",
  "id": "1512",
  "name": "all fours squad stretch",
  "target": "quads"
},
{
  "bodyPart": "waist",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/0lz4ocyaq6G3RN",
  "id": "0006",
  "name": "alternate heel touchers",
  "target": "abs"
},
{
  "bodyPart": "back",
  "equipment": "cable",
  "gifUrl": "https://api.exercisedb.io/image/7uAonq9Cye6YZF",
  "id": "0007",
  "name": "alternate lateral pulldown",
  "target": "lats"
},
{
  "bodyPart": "lower legs",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/Ps8tz5BdMgPDYP",
  "id": "1368",
  "name": "ankle circles",
  "target": "calves"
},
{
  "bodyPart": "back",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/vXVjMcaEu94rx6",
  "id": "3293",
  "name": "archer pull up",
  "target": "lats"
},
{
  "bodyPart": "chest",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/mR0OOL6Xm9azMg",
  "id": "3294",
  "name": "archer push up",
  "target": "pectorals"
},
{
  "bodyPart": "waist",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/Wiy5ArZg6Knk5t",
  "id": "2355",
  "name": "arm slingers hanging bent knee legs",
  "target": "abs"
},
{
  "bodyPart": "waist",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/zDBGX1U6ExLRYC",
  "id": "2333",
  "name": "arm slingers hanging straight legs",
  "target": "abs"
},
{
  "bodyPart": "upper legs",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/34jNq0OfdGMrr5",
  "id": "3214",
  "name": "arms apart circular toe touch (male)",
  "target": "glutes"
},
{
  "bodyPart": "waist",
  "equipment": "body weight",
  "gifUrl": "https://api.exercisedb.io/image/o-rRoH9ItOpmKA",
  "id": "3204",
  "name": "arms overhead full sit-up (male)",
  "target": "abs"
},
{
  "bodyPart": "chest",
  "equipment": "leverage machine",
  "gifUrl": "https://api.exercisedb.io/image/myF7S3Eolu6DTA",
  "id": "0009",
  "name": "assisted chest dip (kneeling)",
  "target": "pectorals"
},
{
  "bodyPart": "waist",
  "equipment": "assisted",
  "gifUrl": "https://api.exercisedb.io/image/AwOx-LwXnpJy4m",
  "id": "0011",
  "name": "assisted hanging knee raise",
  "target": "abs"
},
{
  "bodyPart": "upper legs",
  "equipment": "band",
  "gifUrl": "https://api.exercisedb.io/image/HhsCFvoE0bZoxF",
  "id": "0980",
  "name": "band bent-over hip extension",
  "target": "glutes"
},
{
  "bodyPart": "waist",
  "equipment": "band",
  "gifUrl": "https://api.exercisedb.io/image/Ao9YgkdWJA2KaX",
  "id": "0972",
  "name": "band bicycle crunch",
  "target": "abs"
},
{
  "bodyPart": "back",
  "equipment": "band",
  "gifUrl": "https://api.exercisedb.io/image/jqosopG7K2HjmB",
  "id": "0974",
  "name": "band close-grip pulldown",
  "target": "lats"
}];


export default function ExerciseSingleCard(props) {
  return (
    <div className="maincardWrapper">

      {sampledata.map((product) => {
        return (
          <article key={product.id}>

            <Card className='singleexercisecard' >
              <Card.Img className='singleexercisecardImage' variant="top" src={product.gifUrl} />

              <Card.Body className='singleexercisecardBody'>
                {/* <Card.Title>{product.name}</Card.Title> */}
                <Card.Text>
                  <div className="singleexercisecardinfo">
                    <span style={{ fontWeight: 800 }}>Difficulty - </span>{"Intermediate"}<br />
                    <span style={{ fontWeight: 800 }}>Body Parts - </span>{product.bodyPart}<br />
                    <span style={{ fontWeight: 800 }}>Target Muscles - </span>{product.equipment}<br />
                  </div>
                  <div className="singleexercisecardsteps">
                    <ol>
                      <li>Stance width can vary with a sumo DL. Some prefer toes near the plates. Others prefer a more narrow stance to get more drive off the</li>
                      <li>Make sure your arms are inside your legs. The bar should be just over your mid-foot. Flatten your back and push your heels into the floor.</li>
                      <li>Try to extend your knees and hips simultaneously.</li>
                    </ol>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </article>
        )
      }
      )}
    </div>
  )
}
